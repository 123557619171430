import {lazy, Suspense} from 'react';
import {Navigate} from 'react-router-dom';
import {PartialRouteObject} from 'react-router';

import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';

import DrawerLayout from 'src/layouts/DrawerLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import AccessVerification from "./components/AccessVerification";
import AccountAccessArea from "./enums/AccountAccessArea";
import LoadingOverlay from "./components/LoadingOverlay";

const Loader = (Component) => (props) => (
  <Suspense fallback={<LoadingOverlay active={false}/>}>
    <Component {...props} />
  </Suspense>
);

// Management

const Users = Loader(lazy(() => import('src/content/management/Users')));
const SingleUser = Loader(lazy(() => import('src/content/management/Users/single')));

const Products = Loader(lazy(() => import('src/content/management/Products')));
const AddProduct = Loader(lazy(() => import('src/content/management/Products/add')));
const SingleProduct = Loader(lazy(() => import('src/content/management/Products/single')));

const Coupons = Loader(lazy(() => import('src/content/management/Coupons')));
const AddCoupon = Loader(lazy(() => import('src/content/management/Coupons/add')));
const SingleCoupon = Loader(lazy(() => import('src/content/management/Coupons/single')));
const AddFastCoupon = Loader(lazy(() => import('src/content/management/FastCoupon/add')));

const BeachCards = Loader(lazy(() => import('src/content/management/BeachCards')));
const AddBeachCard = Loader(lazy(() => import('src/content/management/BeachCards/add')));
const SingleBeachCard = Loader(lazy(() => import('src/content/management/BeachCards/single')));

const Beach = Loader(lazy(() => import('src/content/management/Beach')));
const AddBeachItem = Loader(lazy(() => import('src/content/management/Beach/add')));
const SingleBeachItem = Loader(lazy(() => import('src/content/management/Beach/single')));

const Staff = Loader(lazy(() => import('src/content/management/Staff')));
const AddStaffAccount = Loader(lazy(() => import('src/content/management/Staff/add')));
const SingleStaffAccount = Loader(lazy(() => import('src/content/management/Staff/single')));

const Bookings = Loader(lazy(() => import('src/content/management/Bookings')));
const AddBooking = Loader(lazy(() => import ('src/content/management/Bookings/add')));
const SingleBooking = Loader(lazy(() => import('src/content/management/Bookings/single')));
const CheckIns = Loader(lazy(() => import('src/content/management/Checkins')));
const MoveItem = Loader(lazy(() => import('src/content/management/MoveItem')));
// Auth

const Dashboard = Loader(lazy(() => import ('src/content/Dashboard')));
const BookingAnalytics = Loader(lazy(() => import('src/content/Analytics/Bookings')));

const AccountSettings = Loader(lazy(() => import('src/content/account/Settings')));
const ChangePassword = Loader(lazy(() => import('src/content/account/Settings/ChangePassword')));

const StructureSettings = Loader(lazy(() => import('src/content/Structure/Settings')));

const LoginCover = Loader(lazy(() => import('src/content/pages/Auth/Login/Cover')));
const LoginBasic = Loader(lazy(() => import('src/content/pages/Auth/Login/Basic')));

const RegisterCover = Loader(lazy(() => import('src/content/pages/Auth/Register/Cover')));
const RegisterBasic = Loader(lazy(() => import('src/content/pages/Auth/Register/Basic')));
const RegisterWizard = Loader(lazy(() => import('src/content/pages/Auth/Register/Wizard')));

const RecoverPassword = Loader(lazy(() => import('src/content/pages/Auth/RecoverPassword')));
const ResetPassword = Loader(lazy(() => import('src/content/pages/Auth/ResetPassword')));
// Status

const AccessDenied = Loader(lazy(() => import('src/content/pages/Status/AccessDenied')));
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));


const routes: PartialRouteObject[] = [
  {
    path: 'account',
    children: [
      {
        path: 'login',
        element: (
          <Guest>
            <LoginCover />
          </Guest>
        )
      },
      {
        path: 'register',
        element: (
          <Guest>
            <RegisterCover />
          </Guest>
        )
      },
      {
        path: 'login-basic',
        element: <LoginBasic />
      },
      {
        path: 'login-cover',
        element: <LoginCover />
      },
      {
        path: 'reset-password',
        element: <RecoverPassword />
      },
      {
        path: 'reset-password-confirm',
        element: <ResetPassword/>
      },
      {
        path: 'register',
        element: (
          <Guest>
            <RegisterCover />
          </Guest>
        )
      },
      {
        path: 'register-basic',
        element: <RegisterBasic />
      },
      {
        path: 'register-cover',
        element: <RegisterCover />
      },
      {
        path: 'register-wizard',
        element: <RegisterWizard />
      },
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element:           <Guest>
          <LoginCover />
        </Guest>
      },
      {
        path: '/',
        element: (
            <Navigate
                to="/"
                replace
            />
        )
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: (
                <Navigate
                    to="404"
                    replace
                />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'access-denied',
            element: <AccessDenied/>
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: 'account',
    element: (
        <Authenticated>
          <DrawerLayout />
        </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
            <Navigate
                to="/account/settings"
                replace
            />
        )
      },
      {
        path: 'settings',
        children: [
          {
            path: '/',
            element: <AccountSettings/>
          }
        ]
      },
      {
        path: 'settings/password',
        children: [
          {
            path: '/',
            element: <ChangePassword/>
          }
        ]
      },
    ]
  },
  {
    path: 'management',
    element: (
      <Authenticated>
        <DrawerLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/users"
            replace
          />
        )
      },
      {
        path: 'users',
        children: [
          {
            path: '/',
            element:
                <AccessVerification area={AccountAccessArea.USERS}>
                  <Users/>
                </AccessVerification>
          },
          {
            path: ':userId',
            element:
                <AccessVerification area={AccountAccessArea.USERS}>
                  <SingleUser/>
                </AccessVerification>
          },
        ]
      },
      {
        path: 'user',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="/management/users"
                replace
              />
            )
          },
          {
            path: ':userId',
            element:
                <AccessVerification area={AccountAccessArea.USERS}>
                  <SingleUser/>
                </AccessVerification>
          },
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: (
                <AccessVerification area={AccountAccessArea.BEACH_AND_PRODUCTS}>
                  <Products/>
                </AccessVerification>
            )
          },
          {
            path: ":productId",
            element: (
                <AccessVerification area={AccountAccessArea.BEACH_AND_PRODUCTS}>
                  <SingleProduct/>
                </AccessVerification>
            )
          }
        ]
      },
      {
        path: 'product',
        children: [
          {
            path: '/',
            element: (
                <Navigate
                    to="/management/products"
                    replace
                />
            )
          },
          {
            path: '/add',
            element: (
                <AccessVerification area={AccountAccessArea.BEACH_AND_PRODUCTS}>
                  <AddProduct/>
                </AccessVerification>
            )
          },
          {
            path: ':productId',
            element: (
                <AccessVerification area={AccountAccessArea.BEACH_AND_PRODUCTS}>
                  <SingleProduct/>
                </AccessVerification>
            )
          },
        ]
      },
      {
        path: 'coupons',
        children: [{
          path: '/',
          element: (
              <AccessVerification area={AccountAccessArea.COUPONS}>
                <Coupons/>
              </AccessVerification>
          )
        },
          {
            path: ":couponId",
            element: (
                <AccessVerification area={AccountAccessArea.COUPONS}>
                  <SingleCoupon/>
                </AccessVerification>
            )
          }]
      },
      {
        path: 'coupon',
        children: [
          {
            path: '/',
            element: (
                <Navigate
                    to="/management/coupons"
                    replace
                />
            )
          },
          {
            path: '/add',
            element: (
                <AccessVerification area={AccountAccessArea.COUPONS}>
                  <AddCoupon/>
                </AccessVerification>
            )
          },
          {
            path: ':couponId',
            element: (
                <AccessVerification area={AccountAccessArea.COUPONS}>
                  <SingleCoupon/>
                </AccessVerification>
            )
          },
        ]
      },
      {
        path: 'fast_coupon',
        children: [{
          path: '/',
          element: (
              <Navigate
                  to="/management/fast_coupon/add"
                  replace
              />
          )
        },
          {
            path: '/add',
            element: (
                <AccessVerification area={AccountAccessArea.COUPONS}>
                  <AddFastCoupon/>
                </AccessVerification>
            )
          }
        ]
      },
      {
        path: 'cards',
        children: [{
          path: '/',
          element: (
            <AccessVerification area={AccountAccessArea.BEACH_CARDS}>
              <BeachCards />
            </AccessVerification>
          )
        },
          {
            path: ":cardId",
            element: (
              <AccessVerification area={AccountAccessArea.BEACH_CARDS}>
                <SingleBeachCard />
              </AccessVerification>
            )
          }]
      },
      {
        path: 'card',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="/management/cards"
                replace
              />
            )
          },
          {
            path: '/add',
            element: (
              <AccessVerification area={AccountAccessArea.BEACH_CARDS}>
                <AddBeachCard />
              </AccessVerification>
            )
          },
          {
            path: ':cardId',
            element: (
              <AccessVerification area={AccountAccessArea.BEACH_CARDS}>
                <SingleBeachCard />
              </AccessVerification>
            )
          },
        ]
      },
      {
        path: 'beach',
        children: [
          {
            path: '/',
            element: (
                <AccessVerification area={AccountAccessArea.BEACH_AND_PRODUCTS}>
                  <Beach/>
                </AccessVerification>
            )
          },
          {
            path: "/item/:beachItemId",
            element: (
                <AccessVerification area={AccountAccessArea.BEACH_AND_PRODUCTS}>
                  <SingleBeachItem/>
                </AccessVerification>
            )
          }
        ]
      },
      {
        path: 'beach/item',
        children: [
          {
            path: '/',
            element: (
                <Navigate
                    to="/management/beach"
                    replace
                />
            )
          },
          {
            path: 'add',
            element: (
                <AccessVerification area={AccountAccessArea.BEACH_AND_PRODUCTS}>
                  <AddBeachItem/>
                </AccessVerification>
            )
          },
          {
            path: ':beachItemId',
            element: (
                <AccessVerification area={AccountAccessArea.BEACH_AND_PRODUCTS}>
                  <SingleBeachItem/>
                </AccessVerification>
            )
          },
        ]
      },
      {
        path: 'staff',
        children: [
          {
            path: '/',
            element:
                <AccessVerification area={AccountAccessArea.STAFF}>
                  <Staff/>
                </AccessVerification>
          },
          {
            path: "/account/:accountId",
            element:
                <AccessVerification area={AccountAccessArea.STAFF}>
                  <SingleStaffAccount/>
                </AccessVerification>
          }
        ]
      },
      {
        path: 'staff/account',
        children: [
          {
            path: '/',
            element: (
                <Navigate
                    to="/management/staff"
                    replace
                />
            )
          },
          {
            path: 'add',
            element:
                <AccessVerification area={AccountAccessArea.STAFF}>
                  <AddStaffAccount/>
                </AccessVerification>
          },
          {
            path: ':accountId',
            element:
                <AccessVerification area={AccountAccessArea.STAFF}>
                  <SingleStaffAccount/>
                </AccessVerification>
          },
        ]
      },
      {
        path: 'bookings',
        children: [
          {
            path: '/',
            element: (
                <AccessVerification area={AccountAccessArea.CHECK_IN_AND_BOOKINGS}>
                  <Bookings/>
                </AccessVerification>
            )
          },
          {
            path: 'add',
            element: (
                <AccessVerification area={AccountAccessArea.CHECK_IN_AND_BOOKINGS}>
                  <AddBooking/>
                </AccessVerification>
            )          },
          {
            path: ':bookingId',
            element: (
                <AccessVerification area={AccountAccessArea.CHECK_IN_AND_BOOKINGS}>
                  <SingleBooking/>
                </AccessVerification>
            )          }
        ]
      },
      {
        path: 'bookings/move',
        children: [
          {
            path: '/',
            element: (
                <Navigate
                    to="/management/bookings"
                    replace
                />
            )
          },
          {
            path: ':bookingId',
            element: (
                <AccessVerification area={AccountAccessArea.CHECK_IN_AND_BOOKINGS}>
                  <MoveItem/>
                </AccessVerification>
            )          },
        ]
      },
      {
        path: 'checkins',
        children: [
          {
            path: '/',
            element: (
                <AccessVerification area={AccountAccessArea.CHECK_IN_AND_BOOKINGS}>
                  <CheckIns/>
                </AccessVerification>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'structure',
    element: (
        <Authenticated>
          <DrawerLayout />
        </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
            <Navigate
                to="/structure/settings"
                replace
            />
        )
      },
      {
        path: 'settings',
        children: [
          {
            path: '/',
            element:
                <AccessVerification area={AccountAccessArea.STRUCTURE_SETTINGS}>
                  <StructureSettings/>
                </AccessVerification>
          }
        ]
      },
    ]
  },
  {
    path: 'dashboard',
    element: (
        <Authenticated>
          <DrawerLayout />
        </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
            <Navigate
                to="/dashboard/main"
                replace
            />
        )
      },
      {
        path: 'main',
        children: [
          {
            path: '/',
            element: <Dashboard/>
          }
        ]
      }
    ]
  },
  {
    path: 'booking_trend',
    element: (
        <Authenticated>
          <DrawerLayout />
        </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
            <Navigate
                to="/booking_trend/main"
                replace
            />
        )
      },
      {
        path: 'main',
        children: [
          {
            path: '/',
            element:
                <AccessVerification area={AccountAccessArea.BOOKING_STATISTICS}>
                  <BookingAnalytics/>
                </AccessVerification>
          }
        ]
      }
    ]
  },
];

export default routes;
