enum AccountAccessArea {
    CHECK_IN_AND_BOOKINGS = 3,
    BEACH_AND_PRODUCTS = 4,
    COUPONS = 5,
    BOOKING_STATISTICS = 6,
    USERS = 7,
    STAFF = 8,
    STRUCTURE_SETTINGS = 9,
    BEACH_CARDS = 10,
    DASHBOARD = -1,
    ACCOUNT_SETTINGS = -2,
    SUPPORT = -3
}

export default AccountAccessArea;
